.post {
  figure {
    figcaption {
      h4 {
        font-size: 0.8rem;
        color: #808080;
        text-align: center;
      }
    }
  }
}
